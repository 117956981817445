/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { css, jsx } from '@compiled/react';

import { token } from '@atlaskit/tokens';

const noResultsSVGStyles = css({
	height: `120px`,
	marginTop: 0,
	marginRight: 'auto',
	marginBottom: token('space.300', '24px'),
	marginLeft: 'auto',
	display: 'block',
});

const noResultsSVGStyles_handle = css({
	mixBlendMode: 'multiply',
});

export const EmptyStateNoResultsSVG = () => {
	const id = 'link-picker-ui-empty-state-no-results-svg';

	return (
		<svg
			// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			css={noResultsSVGStyles}
			height="202"
			viewBox="0 0 201 202"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath={`url(#${id}-a)`}>
				<rect
					width="200.6"
					height="201.4"
					transform="translate(0 0.400391)"
					fill="white"
					fillOpacity="0.01"
				/>
				<path
					d="M126.8 141.901L117.3 132.501L130.4 119.301L139.9 128.701C142.319 131.083 145.375 132.716 148.7 133.401C152.017 134.165 155.061 135.826 157.5 138.201L196.1 176.301C198.905 179.083 200.5 182.859 200.537 186.81C200.575 190.76 199.052 194.566 196.3 197.401C193.505 200.193 189.721 201.769 185.771 201.788C181.82 201.807 178.022 200.266 175.2 197.501L136.6 159.401C134.176 156.984 132.478 153.935 131.7 150.601C130.98 147.273 129.272 144.241 126.8 141.901Z"
					fill="#CFD4DB"
				/>
				<path
					d="M126.8 141.901L123.9 139.001C123.032 138.145 122.343 137.126 121.873 136.001C121.403 134.877 121.16 133.67 121.16 132.451C121.16 131.232 121.403 130.026 121.873 128.901C122.343 127.777 123.032 126.757 123.9 125.901C124.749 125.027 125.764 124.33 126.885 123.851C128.005 123.372 129.21 123.121 130.429 123.112C131.648 123.102 132.856 123.335 133.984 123.797C135.112 124.259 136.137 124.94 137 125.801L139.9 128.701C142.319 131.084 145.375 132.716 148.7 133.401C152.017 134.166 155.061 135.826 157.5 138.201L196.1 176.301C198.905 179.083 200.5 182.859 200.537 186.81C200.575 190.761 199.052 194.567 196.3 197.401C193.505 200.193 189.721 201.77 185.771 201.788C181.82 201.807 178.021 200.267 175.2 197.501L136.6 159.401C134.176 156.984 132.478 153.935 131.7 150.601C130.98 147.274 129.272 144.242 126.8 141.901Z"
					fill={`url(#${id}-b)`}
				/>
				{/* eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */}
				<g css={noResultsSVGStyles_handle}>
					<path
						d="M131.6 150.301C130.831 147.102 129.166 144.188 126.8 141.901L123.9 139.001C123.032 138.145 122.343 137.126 121.873 136.001C121.403 134.877 121.16 133.67 121.16 132.451C121.16 131.232 121.403 130.026 121.873 128.901C122.343 127.777 123.032 126.757 123.9 125.901C124.749 125.027 125.764 124.33 126.885 123.851C128.005 123.372 129.21 123.121 130.429 123.112C131.648 123.102 132.856 123.335 133.984 123.797C135.112 124.259 136.137 124.94 137 125.801L139.9 128.701C142.201 131.008 145.117 132.605 148.3 133.301C145.957 136.549 143.384 139.623 140.6 142.501C137.789 145.311 134.781 147.918 131.6 150.301Z"
						fill="#FFE380"
					/>
				</g>
				<path
					d="M155.5 77.6001C155.377 62.2312 150.705 47.243 142.073 34.5266C133.441 21.8102 121.236 11.9354 106.998 6.14812C92.7594 0.360847 77.1261 -1.07958 62.0702 2.00857C47.0142 5.09673 33.2102 12.5751 22.3999 23.5001C15.2082 30.7469 9.52542 39.3483 5.68044 48.8062C1.83546 58.2642 -0.095298 68.3909 -5.8712e-05 78.6001C0.0416033 88.8071 2.10435 98.9048 6.06912 108.31C10.0339 117.716 15.8222 126.243 23.0999 133.4C34.0491 144.187 47.948 151.489 63.0429 154.385C78.1378 157.281 93.7522 155.641 107.916 149.671C122.08 143.702 134.158 133.671 142.626 120.844C151.095 108.018 155.575 92.9701 155.5 77.6001Z"
					fill="#0065FF"
				/>
				<path
					d="M35.5999 36.5011C42.4408 29.5751 50.8841 24.443 60.1821 21.5593C69.48 18.6756 79.3456 18.1294 88.905 19.9689C98.4645 21.8084 107.423 25.9769 114.987 32.1052C122.55 38.2336 128.486 46.1326 132.268 55.1028C136.05 64.0729 137.562 73.8373 136.669 83.5311C135.776 93.2249 132.507 102.549 127.15 110.678C121.794 118.806 114.515 125.488 105.959 130.132C97.4035 134.776 87.8346 137.238 78.0999 137.301C66.4111 137.329 54.9748 133.902 45.2277 127.45C35.4807 120.999 27.8577 111.81 23.3165 101.04C18.7753 90.2691 17.5185 78.3966 19.704 66.9139C21.8894 55.4312 27.4197 44.8505 35.5999 36.5011Z"
					fill="#DEEBFF"
				/>
				<path
					d="M40.2 33.3C34.2324 38.2795 29.3188 44.4 25.7471 51.303C22.1754 58.2059 20.0176 65.7524 19.4 73.5C18.0987 89.132 23.0546 104.641 33.1793 116.622C43.3041 128.603 57.7699 136.076 73.4 137.4C85.0685 138.416 96.7757 135.941 107.035 130.291C117.295 124.64 125.644 116.069 131.023 105.664C136.402 95.2598 138.568 83.4917 137.247 71.8538C135.925 60.216 131.175 49.2333 123.6 40.3C118.59 34.3552 112.456 29.4574 105.549 25.8872C98.6428 22.3169 91.0999 20.1444 83.3525 19.4942C75.6052 18.8439 67.8056 19.7287 60.4008 22.0977C52.9959 24.4668 46.1312 28.2736 40.2 33.3Z"
					fill="#B2D4FF"
				/>
				<path
					d="M78.7001 26.7001C70.961 26.045 63.1687 26.9271 55.7718 29.2955C48.3749 31.6639 41.5195 35.4718 35.6001 40.5001C32.1218 43.4231 29.0002 46.746 26.3001 50.4001C21.631 59.038 19.1877 68.7031 19.1909 78.5222C19.1941 88.3412 21.6438 98.0047 26.3185 106.64C30.9932 115.274 37.7457 122.608 45.9659 127.979C54.186 133.35 63.6147 136.588 73.4001 137.4C81.1501 138.105 88.9628 137.256 96.3807 134.903C103.799 132.551 110.673 128.742 116.6 123.7C120.055 120.715 123.173 117.362 125.9 113.7C130.545 105.051 132.965 95.3828 132.944 85.5657C132.923 75.7485 130.461 66.0907 125.78 57.4617C121.098 48.8326 114.344 41.5033 106.126 36.1334C97.9076 30.7636 88.4828 27.5219 78.7001 26.7001Z"
					fill="#DEEBFF"
				/>
				<path d="M77.7002 115.3L120.9 72.0996H92.1002L77.7002 115.3Z" fill="#0065FF" />
				<path d="M63.4001 72.0996L77.7001 115.3L92.1001 72.0996H63.4001Z" fill="#2684FF" />
				<path d="M34.6001 72.0996L77.7001 115.3L63.4001 72.0996H34.6001Z" fill="#B2D4FF" />
				<path d="M106.5 53.6992L92.1001 72.0992H120.9L106.5 53.6992Z" fill="#2684FF" />
				<path d="M77.7001 53.6992L63.4001 72.0992H92.1001L77.7001 53.6992Z" fill="#4C9AFF" />
				<path d="M63.4 72.0992L77.7 53.6992H49L63.4 72.0992Z" fill="#B2D4FF" />
				<path d="M92.1002 72.0992L106.5 53.6992H77.7002L92.1002 72.0992Z" fill="#0065FF" />
				<path d="M49.0001 53.6992L34.6001 72.0992H63.4001L49.0001 53.6992Z" fill="white" />
				<path
					d="M49 64C49.5559 61.496 50.8086 59.2003 52.6134 57.3778C54.4182 55.5553 56.7016 54.2803 59.2001 53.7C56.6989 53.1611 54.4063 51.9121 52.5971 50.1029C50.7879 48.2938 49.539 46.0012 49 43.5C48.4384 45.9907 47.182 48.2711 45.3766 50.0766C43.5712 51.882 41.2908 53.1384 38.8 53.7C41.2933 54.2912 43.5709 55.5697 45.3739 57.3904C47.177 59.2112 48.4332 61.5011 49 64Z"
					fill="#4C9AFF"
				/>
				<path
					d="M109.5 115.3C109.908 113.493 110.82 111.839 112.13 110.53C113.439 109.22 115.093 108.308 116.9 107.9C115.104 107.47 113.461 106.551 112.155 105.245C110.849 103.939 109.93 102.297 109.5 100.5C109.092 102.307 108.18 103.961 106.871 105.27C105.561 106.58 103.907 107.492 102.1 107.9C103.912 108.297 105.572 109.205 106.884 110.516C108.195 111.828 109.103 113.488 109.5 115.3Z"
					fill="white"
				/>
			</g>

			<defs>
				<linearGradient
					id={`${id}-b`}
					x1="200.56"
					y1="162.451"
					x2="121.18"
					y2="162.451"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FF991F" />
					<stop offset="1" stopColor="#FFC400" />
				</linearGradient>
				<clipPath id={`${id}-a`}>
					<rect width="200.6" height="201.4" fill="white" transform="translate(0 0.400391)" />
				</clipPath>
			</defs>
		</svg>
	);
};
