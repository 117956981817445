/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
import React from 'react';

// Note: When the appropriate icons are available in https://atlassian.design/components/icon-object/icon-explorer they
// should be used instead of these.
import Icon, { type IconProps } from '@atlaskit/icon';
import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

// If a Confluence Whiteboards @atlassian/icon/glyph is available,
// Reach out to #help-confluence-whiteboards https://atlassian.slack.com/archives/C02PTHXKDUY
export const Whiteboard16Icon = (props: IconProps) => (
	<Icon
		{...props}
		glyph={() => (
			<svg
				width="16"
				height="16"
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M2 0C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H2ZM6.76162 3.41159C7.86083 2.11703 9.46511 1.64478 10.6195 2.27467C10.7705 2.35706 10.9977 2.49509 11.1722 2.71987C11.373 2.97851 11.4727 3.31107 11.409 3.68304C11.3524 4.01358 11.1765 4.31922 10.9546 4.60153C10.8863 4.68851 10.8097 4.77814 10.7246 4.87072C11.674 4.60241 12.5151 4.65073 13.1473 5.09225C13.9681 5.6655 14.1134 6.66452 13.9141 7.50611C13.8187 7.90918 13.4146 8.15858 13.0115 8.06316C12.6084 7.96774 12.359 7.56363 12.4545 7.16056C12.5709 6.6688 12.4237 6.41651 12.2884 6.32204C12.1286 6.21042 11.6364 6.05866 10.5554 6.52127C10.3091 6.62667 10.0224 6.63652 9.81961 6.62447C9.59851 6.61132 9.32166 6.56256 9.07282 6.44016C8.84101 6.32614 8.43506 6.03646 8.43173 5.48745C8.42884 5.01198 8.74599 4.65814 9.01233 4.43019C9.3922 4.10508 9.63128 3.85787 9.77522 3.67471C9.8072 3.63401 9.83276 3.59864 9.85315 3.56837C9.50001 3.41882 8.68015 3.4696 7.90504 4.38247C7.63694 4.69821 7.16364 4.73684 6.84789 4.46874C6.53215 4.20064 6.49352 3.72734 6.76162 3.41159ZM4.83333 9.41667C5.56971 9.41667 6.16667 8.81971 6.16667 8.08333C6.16667 7.34695 5.56971 6.75 4.83333 6.75C4.09695 6.75 3.5 7.34695 3.5 8.08333C3.5 8.81971 4.09695 9.41667 4.83333 9.41667ZM4.83333 10.9167C6.39814 10.9167 7.66667 9.64814 7.66667 8.08333C7.66667 6.51853 6.39814 5.25 4.83333 5.25C3.26853 5.25 2 6.51853 2 8.08333C2 9.64814 3.26853 10.9167 4.83333 10.9167ZM9.14834 8.88394C9.53883 8.2494 10.4612 8.2494 10.8517 8.88394L13.0621 12.4759C13.4721 13.1422 12.9928 14 12.2104 14H7.78956C7.00724 14 6.52789 13.1422 6.9379 12.4759L9.14834 8.88394ZM8.68435 12.5L10 10.3621L11.3157 12.5H8.68435Z"
					fill="#00A3BF"
				/>
			</svg>
		)}
		size="small"
	/>
);

// Until a Loom icon is available in @atlaskit/icon-object/glyph we use this svg here
export const Loom16Icon = (props: IconProps) => (
	<Icon
		{...props}
		glyph={() => (
			<svg
				width="16"
				height="16"
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M16 7.1H11.3L15.35 4.75L14.5 3.25L10.45 5.6L12.75 1.5L11.2 0.6L8.85 4.65V0H7.05V4.7L4.75 0.65L3.25 1.5L5.6 5.55L1.5 3.25L0.65 4.75L4.7 7.1H0V8.9H4.7L0.65 11.25L1.5 12.75L5.55 10.4L3.25 14.5L4.8 15.4L7.15 11.35V16H8.95V11.3L11.3 15.35L12.85 14.45L10.5 10.4L14.55 12.75L15.45 11.2L11.4 8.85H16V7.1ZM8 10.4C6.65 10.4 5.55 9.3 5.55 7.95C5.55 6.6 6.65 5.5 8 5.5C9.35 5.5 10.45 6.6 10.45 7.95C10.45 9.35 9.35 10.4 8 10.4Z"
					fill="#625DF5"
				/>
			</svg>
		)}
		size="small"
	/>
);

export const Database16Icon = (props: IconProps) => (
	<Icon
		{...props}
		glyph={() => (
			<svg
				width="16"
				height="16"
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M0 2C0 0.895431 0.895431 0 2 0H14C15.1046 0 16 0.895431 16 2V14C16 15.1046 15.1046 16 14 16H2C0.895431 16 0 15.1046 0 14V2ZM3 4C3 3.44772 3.44772 3 4 3H5C5.55228 3 6 3.44772 6 4C6 4.55228 5.55228 5 5 5H4C3.44772 5 3 4.55228 3 4ZM4 7C3.44772 7 3 7.44772 3 8C3 8.55229 3.44772 9 4 9H5C5.55228 9 6 8.55229 6 8C6 7.44772 5.55228 7 5 7H4ZM3 12C3 11.4477 3.44772 11 4 11H5C5.55228 11 6 11.4477 6 12C6 12.5523 5.55228 13 5 13H4C3.44772 13 3 12.5523 3 12ZM8 3C7.44772 3 7 3.44772 7 4C7 4.55228 7.44772 5 8 5H12C12.5523 5 13 4.55228 13 4C13 3.44772 12.5523 3 12 3H8ZM7 8C7 7.44772 7.44772 7 8 7H12C12.5523 7 13 7.44772 13 8C13 8.55229 12.5523 9 12 9H8C7.44772 9 7 8.55229 7 8ZM8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H12C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11H8Z"
					fill="#8270DB"
				/>
			</svg>
		)}
		size="small"
	/>
);

export const Embed16Icon = (props: IconProps) => (
	<Icon
		{...props}
		glyph={() => (
			<svg
				width="16"
				height="16"
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect width="16" height="16" rx="3" fill="#82B536" />
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M8.57067 3.638L7.946 4.25133C7.88196 4.31359 7.83105 4.38805 7.79628 4.47033C7.76152 4.5526 7.7436 4.64101 7.7436 4.73033C7.7436 4.81965 7.76152 4.90806 7.79628 4.99033C7.83105 5.07261 7.88196 5.14708 7.946 5.20933C8.0764 5.33666 8.25142 5.40795 8.43367 5.40795C8.61592 5.40795 8.79095 5.33666 8.92134 5.20933L9.57734 4.56533C10.222 3.932 11.272 3.80866 11.9787 4.37333C12.1677 4.52241 12.3227 4.71006 12.4334 4.92377C12.5442 5.13749 12.6081 5.37236 12.6209 5.61272C12.6337 5.85308 12.5951 6.09341 12.5077 6.31768C12.4203 6.54196 12.2861 6.74502 12.114 6.91333L10.1787 8.81466C9.84918 9.1362 9.40705 9.31618 8.94667 9.31618C8.48629 9.31618 8.04416 9.1362 7.71467 8.81466L6.97467 8.088L6 9.04666L6.73867 9.77266C7.95334 10.9647 9.94 10.9647 11.1533 9.77266L13.09 7.87133C13.3971 7.56973 13.6367 7.20631 13.7928 6.80515C13.9489 6.40399 14.018 5.97424 13.9955 5.54437C13.973 5.1145 13.8594 4.69431 13.6623 4.31164C13.4651 3.92897 13.189 3.59255 12.852 3.32466C11.59 2.31333 9.722 2.50733 8.57067 3.638Z"
					fill={token('color.icon.inverse', N0)}
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M7.42934 13.0287L8.054 12.4153C8.11805 12.3531 8.16896 12.2786 8.20373 12.1963C8.23849 12.1141 8.2564 12.0256 8.2564 11.9363C8.2564 11.847 8.23849 11.7586 8.20373 11.6763C8.16896 11.5941 8.11805 11.5196 8.054 11.4573C7.92364 11.3302 7.74876 11.259 7.56667 11.259C7.38458 11.259 7.2097 11.3302 7.07934 11.4573L6.42267 12.1013C5.778 12.7347 4.728 12.858 4.02134 12.2933C3.83236 12.1443 3.67733 11.9566 3.56659 11.7429C3.45585 11.5292 3.39195 11.2943 3.37914 11.0539C3.36633 10.8136 3.40492 10.5733 3.49232 10.349C3.57972 10.1247 3.71393 9.92164 3.886 9.75333L5.82134 7.852C6.15083 7.53046 6.59296 7.35048 7.05334 7.35048C7.51371 7.35048 7.95585 7.53046 8.28534 7.852L9.02534 8.57866L10 7.62L9.26134 6.894C8.04667 5.702 6.06 5.702 4.84667 6.894L2.91 8.79533C2.60286 9.09693 2.36331 9.46035 2.20721 9.86151C2.05111 10.2627 1.98202 10.6924 2.00452 11.1223C2.02702 11.5522 2.1406 11.9724 2.33773 12.355C2.53487 12.7377 2.81105 13.0741 3.148 13.342C4.41 14.3533 6.278 14.1593 7.42934 13.0287Z"
					fill={token('color.icon.inverse', N0)}
				/>
			</svg>
		)}
		size="small"
	/>
);
