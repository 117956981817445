import { type AriConstructorType, ResourceIdentifier } from '@atlassian/cs-ari';
import {
	type AggregatorTownsquareResponse,
	type Avatar,
	createClient,
	type IssueDashboardBoardProjectFilterResponse,
	type IssueResponse,
	type LoomResponse,
	type PageBlogResponse,
	Scope,
	type SearchClient,
	type TownsquareResponse,
} from '@atlassian/search-client';

import { type LinkContentType, type QuickSearchResult, type SearchProvider } from '../../types';

const ANALYTICS_IDENTIFIER = 'link-picker';

const JIRA_DASHBOARD_URL_PATTERN = new RegExp(/(.*)\/issues\/\?dashboard=(\d+)$/);

export default class SearchResource implements SearchProvider {
	private client: SearchClient;
	private scope: Scope;
	private cloudId: string;
	private cloudIds: string[];
	private analyticsIdentifier: string;
	private useConfluenceTypeInAri: boolean;

	constructor(
		cloudId: string,
		scope: Scope,
		aggregatorUrl?: string,
		analyticsIdentifier: string = ANALYTICS_IDENTIFIER,
		useConfluenceTypeInAri: boolean = false,
		cloudIds: string[] = [],
	) {
		switch (scope) {
			case Scope.ConfluencePageBlog:
			case Scope.ConfluencePageBlogWhiteboard:
			case Scope.ConfluenceContentType:
			case Scope.JiraIssue:
			case Scope.JiraDashboard:
			case Scope.JiraIssueDashboardBoardProjectFilter:
			case Scope.TownsquareProject:
			case Scope.TownsquareGoal:
			case Scope.Loom:
				break;
			default:
				throw new Error(`Unsupported scope - ${scope}`);
		}
		this.scope = scope;
		this.cloudId = cloudId;
		this.cloudIds = cloudIds;
		this.analyticsIdentifier = analyticsIdentifier;
		this.useConfluenceTypeInAri = useConfluenceTypeInAri;

		const config = {
			cloudId,
			aggregatorUrl,
		};
		this.client = createClient(config);
	}

	private getAriString(opt: AriConstructorType): string {
		try {
			return new ResourceIdentifier(opt).toString();
		} catch (e) {
			return '';
		}
	}

	private confluencePageBlogResponseTransformer(
		response: PageBlogResponse | null,
	): QuickSearchResult[] {
		if (!response) {
			return [];
		}

		return response.results.map((item) => {
			const result: QuickSearchResult = {
				title: item.title,
				// The `item.url` is only the relative path so we need to prefix the `item.baseUrl`
				url: item.baseUrl + item.url,
				container: item.container.title,
				contentType: this.mapToContentType(item.content && item.content.type),
				updatedTimestamp: item.lastModified,
				objectId:
					this.cloudIds.length === 0
						? this.getAriString({
								resourceOwner: 'confluence',
								cloudId: this.cloudId,
								resourceType: (this.useConfluenceTypeInAri && item.content?.type) || 'content',
								resourceId: item.content && item.content.id,
							})
						: item.baseUrl + item.url,
			};

			if (item.subtype) {
				result.subtype = item.subtype;
			}
			return result;
		});
	}

	private getIconUrlFromAvatar(avatar: Avatar): string {
		if ('urls' in avatar) {
			return avatar.urls['16x16'];
		} else {
			return avatar.url;
		}
	}

	private jiraResponseTransformer(
		response: IssueDashboardBoardProjectFilterResponse | IssueResponse | null,
	): QuickSearchResult[] {
		if (!response) {
			return [];
		}

		return response.results.map((item) => {
			// The `item.url` here for Jira content is _usually_ absolute url, except for dashboard where the url is wrong
			// It respond with https://site/issues/?dashboard={dashboardId}, but the url needs to be
			// https://site/jira/dashboards/{dashboardId}
			const dashboardMatch = JIRA_DASHBOARD_URL_PATTERN.exec(item.url);
			const url = dashboardMatch
				? `${dashboardMatch[1]}/jira/dashboards/${dashboardMatch[2]}`
				: item.url;

			return {
				title: item.name,
				url: url,
				container:
					('container' in item.attributes &&
						item.attributes.container &&
						item.attributes.container.title) ||
					'',
				contentType: this.mapToContentType(
					item.attributes['@type'],
					'issueTypeName' in item.attributes ? item.attributes.issueTypeName : undefined,
				),
				updatedTimestamp: 'updated' in item.attributes ? item.attributes.updated : undefined,
				iconUrl:
					'avatar' in item.attributes && item.attributes.avatar
						? this.getIconUrlFromAvatar(item.attributes.avatar)
						: undefined,
				objectId:
					this.cloudIds.length === 0
						? this.getAriString({
								resourceOwner: 'jira',
								cloudId: this.cloudId,
								resourceType: item.attributes['@type'],
								resourceId: item.id,
							})
						: url,
			};
		});
	}

	private townsquareProjectResponseTransformer(
		response: AggregatorTownsquareResponse | null,
	): QuickSearchResult[] {
		if (!response) {
			return [];
		}

		const townsquareResponseToQuickSearch = (item: TownsquareResponse): QuickSearchResult => {
			const { title, url, iconUrl } = item;
			return {
				title,
				// The `item.url` in Townsquare/Atlas response is absolute url
				url,
				iconUrl,
				// There isn't any container like attribute in the response
				container: '',
				contentType: this.mapToContentType('townsquareProject'),
				objectId: url,
			};
		};

		return response.results.map(townsquareResponseToQuickSearch);
	}

	private townsquareGoalResponseTransformer(
		response: AggregatorTownsquareResponse | null,
	): QuickSearchResult[] {
		if (!response) {
			return [];
		}

		const townsquareResponseToQuickSearch = (item: TownsquareResponse): QuickSearchResult => {
			const { id, title, url, iconUrl } = item;
			return {
				title,
				// The `item.url` in Townsquare/Atlas response is absolute url
				url,
				iconUrl,
				// There isn't any container like attribute in the response
				container: '',
				contentType: this.mapToContentType('townsquareGoal'),
				objectId: id,
			};
		};

		return response.results.map(townsquareResponseToQuickSearch);
	}

	private LoomResponseTransformer(response: LoomResponse | null): QuickSearchResult[] {
		if (!response) {
			return [];
		}

		return response.results.map((item) => ({
			title: item.name,
			url: item.url,
			container: item.workspaceName,
			contentType: this.mapToContentType('loom'),
			objectId: item.url,
			updatedTimestamp: item.activityDate || item.createdAt,
		}));
	}

	private mapToContentType(
		type?:
			| 'page'
			| 'blogpost'
			| 'dashboard'
			| 'project'
			| 'board'
			| 'filter'
			| 'whiteboard'
			| 'database'
			| 'embed'
			| 'issue'
			| 'townsquareProject'
			| 'townsquareGoal'
			| 'loom'
			| string,
		subType?: string,
	): LinkContentType {
		switch (type) {
			case 'issue':
				switch (subType) {
					case 'Bug':
						return 'jira.issue.bug';
					case 'Story':
						return 'jira.issue.story';
					case 'Task':
						return 'jira.issue.task';
					case 'Epic':
						return 'jira.issue.epic';
					default:
						return 'jira.issue';
				}
			case 'project':
				return 'jira.project';
			case 'board':
				return 'jira.board';
			case 'filter':
				return 'jira.filter';
			case 'dashboard':
				return 'jira.dashboard';
			case 'townsquareProject':
				return 'townsquare.project';
			case 'townsquareGoal':
				return 'townsquare.goal';
			case 'page':
				return 'confluence.page';
			case 'blogpost':
				return 'confluence.blogpost';
			case 'whiteboard':
				return 'confluence.whiteboard';
			case 'database':
				return 'confluence.database';
			case 'embed':
				return 'confluence.embed';
			case 'loom':
				return 'loom.video';
			default:
				return 'default';
		}
	}

	async quickSearch(query: string, limit: number): Promise<QuickSearchResult[]> {
		const result = await this.client.search({
			query,
			scopes: [this.scope],
			analytics: {
				identifier: this.analyticsIdentifier,
			},
			resultLimit: limit,
			sites: this.cloudIds,
		});

		if (
			this.scope === Scope.ConfluencePageBlog ||
			this.scope === Scope.ConfluencePageBlogWhiteboard ||
			this.scope === Scope.ConfluenceContentType
		) {
			const scopeResult = result.response.retrieveScope(this.scope);
			return this.confluencePageBlogResponseTransformer(scopeResult);
		} else if (
			this.scope === Scope.JiraIssue ||
			this.scope === Scope.JiraIssueDashboardBoardProjectFilter
		) {
			const scopeResult = result.response.retrieveScope(this.scope);
			return this.jiraResponseTransformer(scopeResult);
		} else if (this.scope === Scope.TownsquareProject) {
			const scopeResult = result.response.retrieveScope(this.scope);
			return this.townsquareProjectResponseTransformer(scopeResult);
		} else if (this.scope === Scope.TownsquareGoal) {
			const scopeResult = result.response.retrieveScope(this.scope);
			return this.townsquareGoalResponseTransformer(scopeResult);
		} else if (this.scope === Scope.Loom) {
			const scopeResult = result.response.retrieveScope(this.scope);
			return this.LoomResponseTransformer(scopeResult);
		} else {
			throw new Error(`Unsupported scope - ${this.scope}`);
		}
	}
}
